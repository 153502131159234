import axios from "axios";
import { AttributeItem, CategoryItem, GiftCardItem, MamapediaCategoryItem, NoteResponse, VoucherItem, VoucherListResponse } from "./constants";
import { CalculateResultParamItemProps, CalculatedResultItemProps, CartProductItemProps } from "@/components/Cart/constants";
import { AddressItem, initResult, PopupItem, SuggestSearchProps, User } from "@/stores/initialState";
import { AddToCartProductItemProps, MinProductItemProps, ProductDetailItemProps, ProductVariantItemProps } from "@/components/Product/constants";
import { PlusCartProductItemProps } from "@/components/Cart/CartProduct";
import CryptoJS from 'crypto-js';
import axiosInstance from "./axiosInstance";


export const getCategories = async (): Promise<Array<CategoryItem>> => {
  try {  
    const response = await axiosInstance.get(
      process.env.NEXT_PUBLIC_API_URL! + process.env.NEXT_PUBLIC_API_CATEGORY!
    );

    return response.data;
  } catch (error) {
    return []
  }
}

export const getAttributes = async (): Promise<Array<AttributeItem>> => {
  try {
    const response = await axiosInstance.get(
      process.env.NEXT_PUBLIC_API_URL! + process.env.NEXT_PUBLIC_API_ATTRIBUTE!
    );

    return response.data;
  } catch (error) {
    return []
  }
}

export const fetchPopup = async (token: string): Promise<Array<PopupItem>> => {
  try {
    const response = await axiosInstance.get(
      process.env.NEXT_PUBLIC_API_URL! + process.env.NEXT_PUBLIC_API_POPUP!,
      { headers: { Authorization: token } }
    );

    if (response.data.status === "ok") {
      return response.data.data;
    } else {
      return []
    }
  } catch (error) {
    return []
  }
}

export const fetchSuggestSearch = async (): Promise<SuggestSearchProps> => {
  try {
    const response = await axiosInstance.get("/api/suggest_search");

    if (response.data.status === "ok") {
      return {
        keywords: response.data.data.keywords,
        products: response.data.data.products
      }
    } else {
      return {
        keywords: [],
        products: []
      }
    }
  } catch (error) {
    return {
      keywords: [],
      products: []
    }
  }
}

export const getCalculatedResult = async (
  data: CalculateResultParamItemProps, token: string
): Promise<CalculatedResultItemProps> => {
  try {
    const response = await axiosInstance.post(
      process.env.NEXT_PUBLIC_API_URL! + process.env.NEXT_PUBLIC_API_ORDER! + "/calculate",
      data,
      { headers: { Authorization: token } }
    );

    if (response.data.status !== "ok") {
      await send_report({...data, type: "getCalculatedResult", error: response.data});
    }

    return response.data;
  } catch (error: any) {
    if (error?.message !== "Duplicate request detected") {
      await send_report({...data, type: "getCalculatedResult", error: error?.response?.data || error});
    }

    return initResult;
  }
}

export const fetchCarts = async (token: string, category?: string): Promise<Array<CartProductItemProps> | null> => {
  try {
    const params = category && category !== null && category !== undefined ? { category: category } : {};

    const response = await axiosInstance.get(
      "/api/cart",
      { headers: { Authorization: token }, params: params }
    );

    return response.data;
  } catch (error: any) {
    if (error?.message !== "Duplicate request detected" && error?.response?.data.error !== "error") {
      await send_report({type: "fetchCarts", error: error?.response?.data || error});
    }

    return null;
  }
}

export const fetchVouchers = async (token: string, data: any): Promise<VoucherListResponse | Array<VoucherItem>> => {
  try {
    const response = await axiosInstance.post(
      "/api/vouchers",
      data,
      { headers: { Authorization: token } }
    )

    if (response.data.status === "ok" || response.status === 200) {
      return response.data;
    } else {
      await send_report({error: response.data, type: "fetchVouchers"});

      return {
        status: "error",
        gift_card_list: [],
        voucher_list: [],
        note: null
      };
    }
  } catch (error: any) {
    if (error?.message !== "Duplicate request detected") {
      await send_report({...data, error: error?.response?.data || error, type: "fetchVouchers"});
    }

    return {
      status: "error",
      gift_card_list: [],
      voucher_list: [],
      note: null
    };
  }
}

export const bindVoucher = async (token: string, data: any): Promise<VoucherItem | string> => {
  try {
    const response = await axiosInstance.post(
      "/api/vouchers",
      data,
      { headers: { Authorization: token } }
    )

    if (response.data.status === "ok") {
      return response.data;
    } else {
      let desc = "";

      switch (response.data.error) {
        case "Invalid credentials":
          desc = "Terjadi kesalahan, silahkan login ulang dan coba lagi";
          break;

        case "you already have this voucher":
          desc = "Anda sudah memiliki voucher ini";
          break;

        case "this voucher can only be used for new customer":
          desc = "Voucher ini hanya berlaku untuk new customer";
          break;

        case "this coupon is only available to customers who have already purchased":
          desc = "Voucher ini hanya berlaku untuk customer yang sudah pernah melakukan pembelian";
          break;

        case "already used this voucher":
          desc = "Voucher ini telah anda gunakan";
          break;

        case "personal usage has reached the upper limit":
          desc = "Voucher ini telah digunakan, penggunaan voucher sudah mencapai limit";
          break;

        case "the number of coupons used is full":
          desc = "Voucher terbatas, penggunaan voucher sudah mencapai limit";
          break;

        case "cannot find voucher":
          desc = "Voucher tidak ditemukan, silahkan periksa ulang";
          break;

        case "this coupon is bound to someone who can use it":
          desc = "Gagal, voucher tidak ada atau hanya berlaku untuk customer tertentu";
          break;

        default:
          desc = "Gagal, voucher tidak ada atau hanya berlaku untuk level tertentu";
      }

      return desc;
    }
  } catch (error: any) {
    if (error?.message !== "Duplicate request detected") {
      await send_report({...data, error: error?.response?.data || error, type: "bindVoucher"});
    }

    return error.response.data.error;
  }
}

export const bindGiftCard = async (token: string, data: any): Promise<GiftCardItem | string> => {
  try {
    const response = await axiosInstance.post(
      "/api/gift_card",
      data,
      { headers: { Authorization: token } }
    )

    if (response.data.status === "ok") {
      return response.data;
    } else {
      let desc = "";

      switch (response.data.error) {
        case "Invalid credentials":
          desc = "Terjadi kesalahan, silahkan login ulang dan coba lagi";
          break;

        case "this gift card has been bound by another customer":
          desc = "Gift Card ini telah ditambahkan oleh akun lain";
          break;

        default:
          desc = "Gagal, gift card tidak berlaku";
      }

      return desc;
    }
  } catch (error: any) {
    if (error?.message !== "Duplicate request detected") {
      await send_report({...data, error: error?.response?.data || error, type: "bindGiftCard"});
    }

    return error.response.data.error;
  }
}

export const fetchNote = async (): Promise<NoteResponse | null> => {
  try {
    const response = await axiosInstance.get("/api/note");

    return response.data;
  } catch (error) {
    return null;
  }
}

export const fetchUser = async (token: string, user_id: number | undefined): Promise<User | string> => {
  try {
    const response = await axiosInstance.get(
      "/api/user",
      { headers: { Authorization: token } }
    );

    return response.data;
  } catch (error: any) {
    if (error?.message !== "Duplicate request detected") {
      await send_report({error: error?.response?.data || error, user_id: user_id, type: "fetchUser"});
    }

    if (axios.isAxiosError(error)) {
      return error.response?.data.error;
    } else {
      return "error";
    }
  }
}

export const getMamapediaCategories = async (): Promise<Array<MamapediaCategoryItem>> => {
  try {
    const response = await axiosInstance.get(
      process.env.NEXT_PUBLIC_API_URL! + process.env.NEXT_PUBLIC_API_MAMAPEDIA_CATEGORY!
    );

    return response.data;
  } catch (error) {
    return []
  }
}

export const getFocusProducts = async (): Promise<Array<MinProductItemProps>> => {
  try {
    const response = await axios.get("/api/focus_products");

    return response.data;
  } catch (error) {
    return [];
  }
}

export const updateArticle = async (slug: string): Promise<null> => {
  try {
    const response = await axiosInstance.post("/api/mamapedia/" + slug);

    return null;
  } catch (error) {
    return null;
  }
}

export const fetchAddresses = async (token: string): Promise<Array<AddressItem>> => {
  try {
    const response = await axiosInstance.get(
      "/api/address",
      { headers: { Authorization: "Bearer " + token } }
    );

    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export const getNewToken = async (
  token: string, refresh_token: string, uid: string, user_id: number | undefined
): Promise<string> => {
  if (refresh_token === "" || refresh_token === undefined) {
    return "error";
  } else {
    try {
      const response = await axiosInstance.post(
        '/api/refresh',
        { token: refresh_token, username: uid },
        { headers: { Authorization: token } }
      );

      return response.data;
    } catch (error: any) {
      if (error?.message !== "Duplicate request detected") {
        await send_report({error: error?.response?.data || error, user_id: user_id, uid: uid, type: "getNewToken"});
      }

      return "error";
    }
  }
}

export const send_report = async (data: object): Promise<string> => {
  try {
    const body = {data: data, timestamp: new Date().getTime()};
    await axios.post(
      "/api/report",
      {data: CryptoJS.AES.encrypt(JSON.stringify(body), process.env.NEXT_PUBLIC_KEY || '').toString()}
    )
  } catch (error) {
    console.log(error);
  }

  return "ok";
}

export const get_sales_price = (
  product: ProductDetailItemProps | MinProductItemProps | AddToCartProductItemProps | PlusCartProductItemProps | CartProductItemProps, variant: ProductVariantItemProps | null
) : {price: number | Array<number>, promotion: boolean} => {
  if (variant === null || variant === undefined) {
    if (product.special_price && product.special_price !== undefined) {
      if (Array.isArray(product.special_price)) {
        let differentPrice = false;

        for (const variant of product.product_variant_list) {
          if (variant.special_price && variant.special_price === product.special_price[1]) {
            differentPrice = true;
            break;
          }
        }

        if (!differentPrice) {
          return {price: product.special_price[0], promotion: false};
        } else {
          return {price: [product.special_price[0], product.special_price[1]], promotion: true};
        }
      } else {
        return {price: product.special_price, promotion: product.special_price === product.sales_price};
      }
    } else {
      return {price: product.sales_price, promotion: product.sales_price === product.msrp || product.msrp === 0};
    }
  } else {
    if (variant.special_price !== undefined && variant.special_price !== null && variant.special_price !== 0) {
      return {price: variant.special_price, promotion: variant.special_price === variant.sales_price};
    } else {
      return {price: variant.sales_price, promotion: false};
    }
  }
}

export const get_original_price = (
  product: ProductDetailItemProps | MinProductItemProps | AddToCartProductItemProps | PlusCartProductItemProps | CartProductItemProps, variant: ProductVariantItemProps | null
) : number | null => {
  if (variant === null || variant === undefined) {
    if (Array.isArray(product.special_price)) {
      let differentPrice = false;

      for (const variant of product.product_variant_list) {
        if (variant.special_price && variant.special_price === product.special_price[1]) {
          differentPrice = true;
          break;
        }
      }

      if (differentPrice) {
        return null;
      } else if (product.msrp !== 0 && product.msrp > product.special_price[1]) {
        return product.msrp;
      } else {
        return product.sales_price;
      }
    } else if ((product.special_price === undefined && (product.sales_price === product.msrp || product.msrp === 0)) || (product.special_price === product.sales_price && product.msrp === 0)) {
      return null;
    } else if (product.msrp !== 0 && product.msrp > product.special_price) {
      return product.msrp;
    } else {
      return product.sales_price;
    }
  } else {
    if (Array.isArray(variant.special_price) || (variant.special_price === variant.sales_price && product.msrp === 0)) {
      return null;
    } else if (variant.msrp && variant.msrp !== 0 && variant.msrp > variant.special_price) {
      return variant.msrp;
    } else if (product.msrp !== 0 && product.msrp > variant.special_price) {
      return product.msrp;
    } else if (variant.special_price !== undefined && variant.special_price !== null && variant.special_price !== 0) {
      return variant.sales_price;
    } else {
      return null;
    }
  }
}
